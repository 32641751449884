<template>
  <b-card-code>
    <div class="custom-search d-flex">
        <b-button variant="outline-primary" @click="showModal()">
          Add new
        </b-button>
    </div>
    <!-- Email View/Detail -->

    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
        style="white-space: nowrap;text-align: center;vertical-align : middle;"
        :columns="columns"
        :rows="employee_homeworks"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex+1 }}
        </span>
        <span v-else-if="props.column.field === 'h_file'">
          <a :href="fileLocation(props.row.h_file)" target="_blank"><i class="fa fa-eye">View</i></a>
        </span>
        <span v-else-if="props.column.field === 'submission_last_date'">
          {{ moment(props.row.submission_last_date).format('DD-MM-Y')}}
        </span>
        <span v-else-if="props.column.field === 'is_active'">
          <template>
            <b-form-checkbox :name="'check-button'+props.row.originalIndex" :id="'check-button'+props.row.originalIndex" @change="statusChange(props.row.id,props.row.originalIndex)" v-model="props.row.is_active" :value="1" switch>
            </b-form-checkbox>
          </template>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template >
              <b-button :variant="'outline-primary'" @click="showHomeworkModal(props.row)">
                <feather-icon class="text-body " icon="EyeIcon" size="15" />
              </b-button>
              <b-button :variant="'outline-danger'" @click="remove(props.row.id)">
                <feather-icon class="text-body " icon="TrashIcon" size="15" />
              </b-button>
              </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['10','15','20','50','100','500']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal ref="my-modal" size="lg"
             hide-footer
             :title="$t('Add new')"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <form ref="add_new_form" action="javascript:void(0)">
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="title"
                    rules="required"
                >
                  <b-form-input
                      v-model="title"
                      name="title"
                      :state="errors.length > 0 ? false:null"
                      placeholder="write title here"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="file"
                    rules="required"
                >
                  <b-form-file
                      v-model="h_file"
                      name="h_file"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Select file"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="submission last date"
                    rules="required"
                >
                  <b-form-datepicker
                      v-model="submission_last_date"
                      name="submission_last_date"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Select submission laste date"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- submit button -->
            <b-col>
              <b-button
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-modal>
    <b-modal ref="student_homework" size="lg"
             hide-footer
             :title="$t('Student homeworks')"
    >
      <!-- table -->
      <vue-good-table
          style="white-space: nowrap;text-align: center;vertical-align : middle;"
          :columns="columns2"
          :rows="selected_homeworks"
          :rtl="direction"
          :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
          :pagination-options="{
        enabled: true,
        perPage:pageLength2
      }"
      >
        <template
            slot="table-row"
            slot-scope="props"
        >
          <!-- Column: index -->
          <span v-if="props.column.field === 'id'">
            {{ props.row.originalIndex+1 }}
          </span>
          <span v-else-if="props.column.field === 'student_id'">
            {{ studentIdByID(props.row.student_id) }}
          </span>
          <span v-else-if="props.column.field === 'name'">
            {{ studentNameByID(props.row.student_id) }}
          </span>
          <span v-else-if="props.column.field === 'h_file'">
          <a :href="fileLocation(props.row.h_file)" target="_blank"><i class="fa fa-eye">View</i></a>
        </span>
          <span v-else-if="props.column.field === 'created_at'">
          {{ moment(props.row.created_at).format('DD-MM-Y')}}
        </span>
        <span v-else-if="props.column.field === 'is_active'">
            {{ moment(selected_row.submission_last_date).format('DDMMY') >= moment(props.row.created_at).format('DDMMY') ? "Submitted On Time":"Late Submit" }}
        </span>
        </template>

        <!-- pagination -->
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
              <b-form-select
                  v-model="pageLength2"
                  :options="['10','15','20','50','100','500']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength2"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BButton,BPagination, BFormGroup, BFormInput, BFormSelect,BFormCheckbox,
  BModal, BRow, BCol,BFormFile,BFormDatepicker,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {required} from '@validations'
import apiCall from "@/libs/axios";
import moment from 'moment';
import {mapGetters} from "vuex";
export default {
  name:'HomeworkView',
  components: {
    BCardCode,
    VueGoodTable,
    BPagination,
    BFormGroup,BFormDatepicker,
    BFormInput,BFormFile,
    BFormSelect,BModal,BRow, BCol,
    BButton,BFormCheckbox,ValidationProvider, ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    classes:{required:true},
    version:{required:true},
    shift:{required:true},
    wing:{required:true},
    student_group:{required:true},
    section:{required:true},
    academic_year:{required:true},
    subject:{required:true},
  },
  data() {
    return {
      moment,
      required,
      title:'',
      h_file:null,
      submission_last_date:null,
      pageLength: 10,
      dir: false,
      selected_row:{},
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'File',
          field: 'h_file',
        },
        {
          label: 'Submission Last Date',
          field: 'submission_last_date',
        },
        {
          label: 'Total Submitted',
          field: 'student_home_works_count',
        },
        {
          label: 'Status',
          field: 'is_active',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      selected_homeworks:[],
      columns2: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Student ID',
          field: 'student_id',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'File',
          field: 'h_file',
        },
        {
          label: 'Submission Date',
          field: 'created_at',
        },
        {
          label: 'Status',
          field: 'is_active',
        },
      ],
      searchTerm2: '',
      pageLength2: 20,
    }
  },
  methods:{
    fileLocation(name){
      return process.env.VUE_APP_ENV_RESOURCHURL+name;
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.add_new_form);
          data.append('classes_id',this.classes);
          data.append('version_id',this.version);
          data.append('shift_id',this.shift);
          data.append('wing_id',this.wing);
          data.append('student_group_id',this.student_group);
          data.append('section_id',this.section);
          data.append('academic_year_id',this.academic_year);
          data.append('subject_id',this.subject);
          apiCall.post('/employee/homework/store',data).then((response)=>{
            if (response.data.status === 'success'){
              this.$toaster.success(response.data.message);
              this.hideModal();
              this.$refs.simpleRules.reset();
              this.$store.dispatch('GET_ALL_EMPLOYEE_HOMEWORK');
            }else {
              this.$toaster.error(response.data.message);
            }
          }).catch((error)=>{
            if (error.response.status ==422) {
              Object.keys(error.response.data.errors).map((field) => {
                this.$toaster.error(error.response.data.errors[field][0]);
              })
            }
            else this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    /*
    *
    * method for status change item
    * */
    statusChange(id,index){
      apiCall.put(`/employee/homework/status/change${id}`).then((response)=>{
        if (response.data.status==='success'){
          this.$toaster.success(response.data.message);
          this.$store.commit('STATUS_CHANGE_EMPLOYEE_HOMEWORK',index);
        }else{
          this.$store.dispatch('GET_ALL_EMPLOYEE_HOMEWORK');
        }
      }).catch((error)=>{
        this.$toaster.error(error.response.data.message);
      });
    },
    /*
    * method for remove item
    * */
    remove(id,index){
      this.$swal({
        title: 'Are you sure?',
        text: "You want to remove this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          apiCall.delete(`/employee/homework/remove${id}`).then((response)=>{
            this.$toaster.success(response.data.message);
            this.$store.commit('REMOVE_EMPLOYEE_HOMEWORK',index);
          }).catch((error)=>{
            this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    showModal() {
      this.$refs['my-modal'].show()
      this.title='';
      this.h_file=null;
    },
    showHomeworkModal(row) {
      this.selected_row=row;
      this.selected_homeworks=row.student_home_works;
      this.$refs['student_homework'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    employee_homeworks(){
      return this.$store.getters.employee_homeworks.filter(item=>{
        if(item.classes_id=== parseInt(this.classes) && item.version_id===parseInt(this.version) &&
            item.shift_id===parseInt(this.shift) && item.wing_id===parseInt(this.wing) &&
            item.student_group_id===parseInt(this.student_group) && item.section_id===parseInt(this.section) &&
            item.academic_year_id===parseInt(this.academic_year) && item.subject_id===parseInt(this.subject)
        ) return item;
      });
    },
    ...mapGetters(['students','studentIdByID','studentNameByID']),
  },
  created() {
    if(this.employee_homeworks.length <1) this.$store.dispatch('GET_ALL_EMPLOYEE_HOMEWORK');
    if(this.students.length <1) this.$store.dispatch('GET_ALL_STUDENT');
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>